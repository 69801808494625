body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

h1 {
  font-family: "Playfair Display", serif;
  font-weight: 700;
}

h2 {
  font-family: "Playfair Display", serif;
  font-weight: 500;
}

h3 {
  font-family: "Gupter", serif;
  font-weight: 500;
}

p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.App {
  text-align: center;
  height: 100vh;
  background-image: url("../public/img/bdprotest.png"); /* Adjust the path to your image */
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.App::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  filter: blur(10px);
  z-index: -1;
}

.content {
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Slightly transparent background */
  padding: 40px;
  border-radius: 10px;
  width: 60%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  z-index: 1;
}

h1 {
  font-size: 2em;
  margin: 20px 0;
}

h2 {
  font-size: 1.5em;
  margin: 20px 0;
}

h3 {
  font-size: 1.2em;
  margin: 20px 0;
}

.dots {
  font-size: 2em;
  margin: 20px 0;
  font-family: "Poppins", sans-serif;
}

p {
  font-size: 1.2em;
  margin: 10px 0;
  text-align: left;
}

.disclaimer {
  font-size: 1em;
  margin: 20px 0;
  text-align: center;
}

.disclaimer span {
  font-size: 2em;
  font-family: "Poppins", sans-serif;
}

@media (max-width: 1200px) {
  .content {
    width: 80%;
    padding: 20px;
  }

  h1 {
    font-size: 1.8em;
  }

  h2 {
    font-size: 1.3em;
  }

  h3 {
    font-size: 1.1em;
  }

  .dots {
    font-size: 1.8em;
  }

  p {
    font-size: 1em;
  }

  .disclaimer {
    font-size: 0.9em;
  }

  .disclaimer span {
    font-size: 1.5em;
  }

  .App {
    height: auto;
    overflow: auto;
  }
}
